<svg width="50" height="{size}">
  <defs>
  <linearGradient id="grad-{id}" x1="0%" y1="100%" x2="0%" y2="0%">
      {#each colorValues as v}
      <stop offset="{v.offset}%" style="stop-color:{v.color};stop-opacity:1" />
      {/each}
    </linearGradient>
  </defs>
  <g transform="translate({margin.left},{margin.top})">
  <rect x="-{margin.left}" y="0" width="12" height="{size-margin.top-margin.bottom}" fill="url('#grad-{id}')" />
    <g ref:colorBar class="axis" />
  </g>
</svg>

<script>
  import * as d3 from 'd3';

  let staticId = 0;

  export default {

    data() {
      staticId = staticId + 1;
      return {
        colorScale: d3.scaleSequential(d3.interpolateGreys),
        size: 150,
        margin: {top: 5, right: 12, bottom: 5, left: 18},
        id: staticId,
      };
    },
    onstate({_changed, current, _previous}) {
      const height = current.size - current.margin.top - current.margin.bottom;
      // FIXME: Handle non-linear case
      const tickScale = d3.scaleLinear().domain(current.colorScale.domain()).range([height, 0]);

      const axisGen = d3.axisRight(tickScale).tickFormat(d3.format('.2f')).ticks(5);
      d3.select(this.refs.colorBar).call(axisGen);
    },

    computed: {
      colorValues: ({colorScale}) => {
        const domain = colorScale.domain();
        const n = 11;
        const step = (domain[1] - domain[0]) / n;
        return d3.range(domain[0], domain[1] + step, step).map((v,i) =>
          ({ offset: (i / n) * 100, color: colorScale(v) }));
      }
    },
  };
</script>
